/* eslint-disable indent */

import { useContext } from 'react';
import { PosthogContext } from '../../providers/PosthogProvider';
import type { FeatureFlags } from '../../types/featureFlag';

type PayloadFeatureFlags = {
  [P in keyof FeatureFlags]: FeatureFlags[P] extends null ? never : P;
}[keyof FeatureFlags];

export const useFeatureFlagPayload = <
  F extends PayloadFeatureFlags = PayloadFeatureFlags
>(
  flag: F
): FeatureFlags[F] | undefined => {
  const { featureFlags } = useContext(PosthogContext);

  const featureFlag = featureFlags.find(({ name }) => name === flag);

  return featureFlag?.payload as FeatureFlags[F] | undefined;
};
