/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  InlineLoading,
  Tooltip,
} from '@carbon/react';
import { Report } from '@carbon/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../providers/AppProvider';
import { PosthogContext } from '../../providers/PosthogProvider';
import type { ResponsePayload } from '../Fetch';
import Fetch from '../Fetch';
import Dimensions from './Dimensions';
import Hierarchy from './Hierarchy/Hierarchy';
import ReportSummary from './ReportSummary';
import SelectionControls from './SelectionControls';
import {
  fetchHierarchiesData,
  getAutoGeneratedName,
  getParameterHierarchy,
  getReportModuleAndSection,
  filterExtraParams,
  hasOverlap,
  addChildrenToNodes,
  fetchMinimumRequirements,
} from '../../utils/reportUtils';
import {
  orderTimeParams,
  fetchReportData,
  getInitialParameters,
  getReportName,
  getSelectionsFromAdvOptions,
  getURLRoutes,
  getInvalidParams,
  getUpdatedOptions,
  getValidParams,
} from './../ReportBuilder/utils/reportBuilderUtils';
import HierarchyTreeProvider from '../../providers/HierarchyTreeProvider';
import { CACHE_KEY } from '../../constants/api';
import '../../styles/components/hierarchy.scss';
import '../../styles/components/reportBuilder.scss';
import ReportSettings from './ReportSettings/ReportSettings';
import { ModalContext } from '../../providers/ModalProvider';
import apiRequest from '../../api';
import usePosthog from '../../utils/posthog';
import {
  reportBuilderClearAllParams,
  reportBuilderDeleteFavouriteFailure,
  reportBuilderDeleteFavouriteSuccess,
  reportBuilderRemoveGroup,
} from '../../constants/posthog';
import IconTitle from '../IconTitle';
import { USER_TYPE } from '../../constants/metadata';
import { useFeatureFlagPayload } from '../../context/posthog';

export interface SelectionObject {
  [key: string]: HierNode[];
}

export interface HierarchyOverlap {
  [key: string]: Overlap;
}

// TEMPORARY: TO BE REMOVED!
// Combined with a temporary backend change, this allows suppliers to see
// store-cluster location hierarchy if a feature flag is set, else continue to
// not see the hierarchy at all
const suppressLocationDimension = ({
  dimensions,
  showStoreCluster,
}: {
  dimensions: Dimension[];
  showStoreCluster: boolean;
}): Dimension[] =>
  dimensions.reduce(
    (dimensionAccumulator, dimension) => {
      if (dimension.dimension !== 'location') {
        return [...dimensionAccumulator, dimension];
      }

      const hierarchies = dimension.hierarchies.filter(
        ({ id }) => showStoreCluster || id !== 'store-cluster'
      );

      return hierarchies.length > 0
        ? [...dimensionAccumulator, { ...dimension, hierarchies }]
        : dimensionAccumulator;
    },

    [] as Dimension[]
  );

const ReportBuilder: FunctionComponent = () => {
  const {
    bannerId,
    groupId,
    dimensions,
    queryCache,
    reportParameters,
    reportModules,
    user,
    updateDimensionsData,
    updateReportModules,
    updateReportParameters,
    updateHierarchyData,
    updateQueryCache,
    updateCalendarData,
    updateFavouriteGroups,
  } = useContext(AppContext);

  const initialDateState: SelectedDate[] = [
    {
      startDate: new Date('2022-01-01'),
      endDate: new Date('2022-01-01'),
      key: 'selection',
    },
  ];

  const initialSpendBandsState: SpendBands = {
    min: 0,
    max: 0,
  };

  const { section, moduleId } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { user_group_ids: supplierStoreClusterUserGroupIds = [] } =
    useFeatureFlagPayload('unsafe-supplier-store-cluster') ?? {};

  const [selectedDimension, setSelectedDimension] = useState(null);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);
  const [includeUpperBand, setIncludeUpperBand] = useState<boolean>(true);
  const [selection, setSelection] = useState<SelectionObject>({});
  const [date, setDate] = useState<SelectedDate[]>(initialDateState);
  const [spendBands, setSpendBands] = useState<SpendBands>(
    initialSpendBandsState
  );
  const [showSubmit, setShowSubmit] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportName, setReportName] = useState(() =>
    getAutoGeneratedName(moduleId)
  );
  const [containsHiddenCustomGroups, setContainsHiddenCustomGroups] =
    useState(false);
  const [groupedHierarchyRules, setGroupedHierarchyRules] =
    useState<GroupedHierarchyRules>({});
  const [advancedSettings, setAdvancedSettings] = useState<
    AdvancedSettings[] | Setting[]
  >([]);
  const [analyticEngineParams, setAnalyticEngineParams] =
    useState<AnalyticEngineParams | null>();
  const [overlap, setOverlap] = useState<HierarchyOverlap>({});
  const [success, setSuccess] = useState(false);
  const [minReq, setMinReq] = useState<RuleSet | null>(null);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const showStoreCluster = supplierStoreClusterUserGroupIds.includes(groupId);

  const editReport = useMemo(() => {
    return {
      id: searchParams.get('edit') ?? searchParams.get('duplicate'),
      isEditAction: !!searchParams.get('edit'),
    };
  }, [searchParams]);

  useEffect(() => {
    const newOverlap = {};
    Object.keys(reportParameters).forEach((key) => {
      if (key.match(/_groups/)) {
        const dimension = key.split('_')[0];
        const selectedGroups = reportParameters[key];
        const overlapError = hasOverlap(selectedGroups as ReportParameter[]);
        newOverlap[dimension] = overlapError;
      }
      if (key.match(/_filters/)) {
        const selectedGroups = reportParameters[key];
        const overlapError = hasOverlap(selectedGroups as ReportParameter[]);
        newOverlap[key] = {
          hasOverlap: false,
          hasFiltersOverlap: overlapError.hasOverlap,
          reason:
            'Parameter selection overlap. Please select one option to either include or exclude.',
        };
      }
    });
    setOverlap(newOverlap);
  }, [reportParameters]);

  const posthogEvent = usePosthog();
  const { featureFlags } = useContext(PosthogContext);

  const modulesFlag = featureFlags.find(
    ({ name, payload }) =>
      name === 'modules-maintenance' &&
      payload &&
      (payload as { banner_ids?: number[] }).banner_ids?.includes(bannerId)
  );

  const reportFlag = featureFlags.find(
    (flag) => flag.name === 'report-maintenance'
  );
  const disabledUrlRoutes: string[] =
    reportFlag && getURLRoutes(reportModules, reportFlag.payload as string[]);

  useEffect(() => {
    if (
      modulesFlag ||
      (reportFlag &&
        disabledUrlRoutes.some((route) => pathname.includes(route)))
    ) {
      navigate('/modules');
    }
  }, [modulesFlag, reportFlag, pathname, disabledUrlRoutes]);

  useEffect(() => {
    if (showSubmit || showAdvanced) {
      setSelectedDimension(null);
      setSelectedHierarchy(null);
    } else {
      setSuccess(false);
    }
  }, [showSubmit, showAdvanced]);

  useEffect(() => {
    updateReportParameters(reportParameters);
    return () => resetAllParameters();
  }, [reportParameters]);

  useEffect(() => {
    if (dimensions.length > 0) {
      setSelectedDimension(dimensions[0]);
    }
  }, [dimensions]);

  useEffect(() => {
    if (selectedDimension) {
      setShowSubmit(false);
      setShowAdvanced(false);
      setSelectedHierarchy(selectedDimension.hierarchies[0]);
    }
  }, [selectedDimension]);

  useEffect(() => {
    if (editReport.id && queryCache.modules && queryCache.reports) {
      getReportParams(
        editReport.id,
        reportParameters,
        editReport.isEditAction,
        dimensions
      );
    }
  }, [editReport.id]);

  useEffect(() => {
    setSelection({});
  }, [selectedDimension, selectedHierarchy]);

  const report = getReportModuleAndSection(reportModules, section, moduleId);
  const hasAdvancedOptions = report && report.advanced_options?.length;

  useEffect(() => {
    if (!minReq && report) {
      getReportMinRequirements(report.url_route);
    }
    return () => {
      setMinReq(null);
    };
  }, [report, editReport.id]);

  useEffect(() => {
    if (
      reportModules?.length &&
      section &&
      moduleId &&
      report.advanced_options?.length
    ) {
      const updatedOptions = getUpdatedOptions(report, reportParameters);

      setAdvancedSettings(updatedOptions);
    }
  }, [report, reportParameters]);

  useEffect(() => {
    if (report) {
      const { advanced_options } = report;

      const defaultSelections = getSelectionsFromAdvOptions(advanced_options);

      setAnalyticEngineParams({
        ...analyticEngineParams,
        ...defaultSelections,
      });
    }
  }, [report]);

  useEffect(() => {
    if (analyticEngineParams) {
      const invalidParams = getInvalidParams(
        analyticEngineParams,
        advancedSettings as AdvancedSettings[]
      );

      if (invalidParams?.length > 0) {
        const validParams = getValidParams(
          invalidParams,
          advancedSettings as AdvancedSettings[],
          reportParameters
        );

        setAnalyticEngineParams({
          ...analyticEngineParams,
          ...validParams,
        });
      }
    }
  }, [report, reportParameters, advancedSettings]);

  const resetAllParameters = () => {
    const emptyParams = {};

    Object.keys(reportParameters).forEach((param) => {
      emptyParams[param] = [];
    });

    updateReportParameters(emptyParams);
  };

  useEffect(() => {
    setSelectedHierarchy(null);
  }, [bannerId, groupId]);

  useEffect(() => {
    if (dimensions.length) {
      const rules = {};

      dimensions.forEach((dim) => {
        if (!rules[dim.dimension]) {
          rules[dim.dimension] = [];
        }

        dim.hierarchies.forEach((hier) => {
          if (!rules[dim.dimension].includes(hier.key)) {
            rules[dim.dimension].push(hier.key);
          }
        });
      });

      setGroupedHierarchyRules(rules);
    }
  }, [dimensions]);

  const resetReport = () => {
    setSuccess(false);
    setReportName('');
    resetAllParameters();
  };

  const updateParameters = (
    parameterKey: string,
    selectedParameters: ReportParameter[]
  ) => {
    selectedParameters.forEach((param) => {
      param.items &&
        param.items.forEach((p) => {
          delete p.label;
        });
    });

    const newParameters = [
      ...(reportParameters[parameterKey] as ReportParameter[]),
      ...selectedParameters,
    ];

    updateReportParameters({
      ...reportParameters,
      [parameterKey]: orderTimeParams(newParameters, selectedHierarchy.id),
    });

    setSelection({});
  };

  const editParameter = (
    key: string,
    parameter: ReportParameter,
    index: number
  ) => {
    const newParameters = (reportParameters[key] as ReportParameter[]).map(
      (param, idx) => {
        return index === idx ? parameter : param;
      }
    );

    updateReportParameters({ ...reportParameters, [key]: newParameters });
  };

  const removeParameter = (key: string, parameter: ReportParameter) => {
    const [dimension, selectionType] = key.split('_');
    const newParameters = (reportParameters[key] as ReportParameter[]).filter(
      (param) => param !== parameter
    );

    updateReportParameters({ ...reportParameters, [key]: newParameters });
    posthogEvent(reportBuilderRemoveGroup, {
      origin: selectedDimension ? selectedDimension.dimension : 'Submit Report',
      dimension,
      selectionType,
      groupName: parameter.name,
    });
  };

  const clearParameters = (
    dimension: string,
    selectionType: 'groups' | 'filters' | 'universes'
  ) => {
    const multiKeys = groupedHierarchyRules[dimension]?.reduce((prev, curr) => {
      prev[curr + `_${selectionType}`] = [];
      return prev;
    }, {});
    updateReportParameters({
      ...reportParameters,
      ...multiKeys,
    });
    posthogEvent(reportBuilderClearAllParams, {
      dimension,
      selectionType,
    });
  };

  const renderData = (data: Dimension[]) => {
    if (!data.length) {
      return;
    }

    const dimensions =
      user.user_type === USER_TYPE.SUPPLIER
        ? suppressLocationDimension({ dimensions: data, showStoreCluster })
        : data;

    const initialParams = getInitialParameters(dimensions);
    updateDimensionsData(dimensions);
    updateReportParameters(initialParams);
    if (editReport.id) {
      getReportParams(
        editReport.id,
        initialParams,
        editReport.isEditAction,
        dimensions
      );
    }
  };

  const getReportParams = async (
    id: string,
    parameters: ReportParameters,
    isEditAction: boolean,
    dimensions?: Dimension[]
  ) => {
    const filteredParams = filterExtraParams(parameters);

    setReportLoading(true);
    const token = await getAccessTokenSilently();

    try {
      const editData = await fetchReportData(id, groupId, bannerId, token);
      if (editData) {
        const reportTemplateIndex = 0;
        const mergedParameters = {
          ...filteredParams,
          ...editData.parameters.template_requests[reportTemplateIndex],
        };

        const uniqueHierarchies = getParameterHierarchy(
          mergedParameters,
          dimensions
        );
        const hierarchiesData = await fetchHierarchiesData(
          uniqueHierarchies,
          bannerId,
          groupId,
          token,
          user.user_type
        );

        hierarchiesData.forEach((hierarchy, index) => {
          const hierarchyId = uniqueHierarchies[index].id;
          if (
            hierarchyId === 'date-range' &&
            hierarchy.start_date &&
            hierarchy.end_date
          ) {
            const dates: FormattedDate = {
              from: hierarchy.start_date,
              to: hierarchy.end_date,
              hierarchy_element: 'date_id',
            };

            updateCalendarData(hierarchyId, [dates]);
          } else {
            updateHierarchyData(hierarchyId, hierarchy);
          }
          updateQueryCache(`${CACHE_KEY.HIERARCHIES}-${hierarchyId}`, bannerId);
        });

        updateReportParameters(mergedParameters);

        setReportName(
          `${editData.report_name}${
            searchParams.has('duplicate') ? '-DUPLICATE' : ''
          }`
        );

        setContainsHiddenCustomGroups(editData?.contains_hidden_group);

        const newOverlap = {};
        uniqueHierarchies.forEach((hierarchy) => {
          const dimension = hierarchy.key + '_groups';
          const selectedGroups = mergedParameters[dimension];

          const parameters = addChildrenToNodes(
            selectedGroups as ReportParameter[],
            hierarchiesData,
            uniqueHierarchies
          );

          const overlapError = hasOverlap(parameters);
          newOverlap[hierarchy.key] = overlapError;
        });

        setOverlap(newOverlap);
        setAnalyticEngineParams(
          mergedParameters.analytic_engine_params as AnalyticEngineParams
        );
      }
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue loading your report details. Please try again, or refresh your browser.',
      });
    } finally {
      setReportLoading(false);
    }
  };

  const checkAdvancedTab = (): boolean => {
    if (!hasAdvancedOptions) {
      return false;
    }
    for (const param in reportParameters) {
      const params = reportParameters[param];
      if (Array.isArray(params) && params.length > 0) {
        return true;
      }
    }

    return !advancedSettings.some((setting: Setting) => {
      return setting.dimension_linked;
    });
  };

  const refetchFavourites = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await apiRequest<ResponsePayload<FavouriteGroup[]>>(
        `/users/${encodeURIComponent(
          user.id
        )}/report-params/favourites?tdp_id=${bannerId}&user_group=${groupId}`,
        'GET',
        token
      );
      if (data) {
        updateFavouriteGroups(data);
      }
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue updating the favourites list. Please try again.',
      });
    }
  };

  const getReportMinRequirements = async (reportType: string) => {
    try {
      const token = await getAccessTokenSilently();
      const res = await fetchMinimumRequirements(reportType, bannerId, token);

      setMinReq(res);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue updating minimum requirements. Please try again.',
      });
    }
  };

  const deleteFavourite = async (
    type: 'group' | 'item',
    hierarchyName: string,
    name: string,
    parentName?: string
  ) => {
    toggleModal(false);
    const groupItems = type === 'item' ? [{ item_name: name }] : null;
    const payload = {
      hierarchy_name: hierarchyName,
      tdp_id: bannerId,
      user_group: groupId,
      favourite_groups: [
        {
          group_name: type === 'group' ? name : parentName,
          group_items: groupItems,
        },
      ],
    };
    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        `/users/${encodeURIComponent(user.id)}/report-params/favourites`,
        'DELETE',
        token,
        payload
      );
      posthogEvent(reportBuilderDeleteFavouriteSuccess);
    } catch (error) {
      posthogEvent(reportBuilderDeleteFavouriteFailure);
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue deleting favourite. Please try again.',
      });
    } finally {
      await refetchFavourites();
    }
  };

  const renderContent = () => {
    if (!queryCache[CACHE_KEY.MODULES]) {
      return (
        <Fetch
          apiUrl={`/configs/${bannerId}/user-groups/${groupId}/modules`}
          cacheKey={CACHE_KEY.MODULES}
          initialData={[]}
          loadingMessage="Loading Modules..."
          onReceiveData={(data) => updateReportModules(data)}
        />
      );
    } else if (reportLoading) {
      return <InlineLoading description="Loading report..." />;
    } else {
      return (
        <div className="report-builder-container">
          <Dimensions
            dimensions={dimensions}
            selectedDimension={selectedDimension}
            setSelectedDimension={setSelectedDimension}
            showSubmit={showSubmit}
            setShowSubmit={setShowSubmit}
            setShowAdvanced={setShowAdvanced}
            showAdvanced={showAdvanced}
            success={success}
            advancedTab={checkAdvancedTab()}
          />

          {!showSubmit &&
            !showAdvanced &&
            selectedHierarchy &&
            dimensions.length > 0 && (
              <div className="controls-container">
                <div className="hierarchy-container">
                  <HierarchyTreeProvider>
                    <Hierarchy
                      selectedDimension={selectedDimension}
                      selectedHierarchy={selectedHierarchy}
                      setSelectedHierarchy={setSelectedHierarchy}
                      selection={selection}
                      setSelection={setSelection}
                      date={date}
                      setDate={setDate}
                      spendBands={spendBands}
                      setSpendBands={setSpendBands}
                      includeUpperBand={includeUpperBand}
                      setIncludeUpperBand={setIncludeUpperBand}
                      deleteFavourite={(type, name, parentName) =>
                        deleteFavourite(
                          type,
                          selectedHierarchy.name,
                          name,
                          parentName
                        )
                      }
                    />
                  </HierarchyTreeProvider>
                </div>
                <div className="selection-container">
                  <SelectionControls
                    selectedDimension={selectedDimension}
                    selectedHierarchy={selectedHierarchy}
                    selectedDate={date}
                    selection={selection}
                    updateParameters={updateParameters}
                    editParameter={editParameter}
                    removeParameter={removeParameter}
                    clearParameters={clearParameters}
                    overlap={overlap}
                    spendBands={spendBands}
                    setSpendBands={setSpendBands}
                    includeUpperBand={includeUpperBand}
                    setIncludeUpperBand={setIncludeUpperBand}
                    groupedHierarchyRules={groupedHierarchyRules}
                    minRequirements={minReq}
                    containsHiddenCustomGroups={containsHiddenCustomGroups}
                  />
                </div>
              </div>
            )}
          {!showSubmit && showAdvanced && (
            <ReportSettings
              advancedSettings={advancedSettings}
              analyticEngineParams={analyticEngineParams}
              setAnalyticEngineParams={setAnalyticEngineParams}
            />
          )}
          {showSubmit && (
            <ReportSummary
              advancedSettings={advancedSettings}
              showSubmit={showSubmit}
              reportName={reportName}
              overlap={overlap}
              setReportName={setReportName}
              editParameter={editParameter}
              removeParameter={removeParameter}
              resetReport={resetReport}
              analyticEngineParams={analyticEngineParams}
              deleteFavourite={deleteFavourite}
              refetchFavourites={refetchFavourites}
              editReportId={editReport.id}
              isEditAction={editReport.isEditAction}
              minRequirements={minReq}
              containsHiddenCustomGroups={containsHiddenCustomGroups}
            />
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div className="breadcrumb-container">
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem>
            <Tooltip label="Return to modules">
              <div className="cds--link" onClick={() => navigate('/modules')}>
                Modules
              </div>
            </Tooltip>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Tooltip label="Return to modules section">
              <div onClick={() => navigate('/modules')}>{section}</div>
            </Tooltip>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{getReportName(reportModules, section, moduleId)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <IconTitle
        icon={<Report size={20} />}
        title="Insight Report Builder"
        kind="primary"
      />

      <div
        className="report-builder-wrapper"
        key={`${queryCache[CACHE_KEY.MODULES]}-${
          queryCache[CACHE_KEY.DIMENSIONS]
        }`}
      >
        <Fetch
          apiUrl={`/configs/${bannerId}/user-groups/${groupId}/hierarchies?report_name=${moduleId}`}
          initialData={[]}
          loadingMessage="Loading Dimensions..."
          onReceiveData={renderData}
          alwaysFetchOnMount
        >
          {renderContent()}
        </Fetch>
      </div>
    </>
  );
};

export default ReportBuilder;
